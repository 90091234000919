import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './scss/style.scss';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register'));
const ForgotPassword = React.lazy(() => import('./views/pages/login/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/login/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                        <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
                        <Route exact path="/forgot" name="Forgot Password Page" render={(props) => <ForgotPassword {...props} />} />
                        <Route exact path="/reset-password/:token" name="Reset Password Page" render={(props) => <ResetPassword {...props} />} />
                        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                        <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
